import React, { useState, useEffect } from "react"
import Modal from "react-modal"
import Cookies from 'universal-cookie'

export default function CookiePopup() {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        const cookies = new Cookies('registered')
        if (cookies.get('registered')) {
            setIsOpen(false); //Modal does not open if cookie exists
        } else if (!cookies.get('registered')) {
            cookies.set('registered', 'true', {
                path: '/',
            })
            setTimeout(function () {
                setIsOpen(true) //Creates a cookie and shows modal.
            }, 3500)
        }
    }, [])

    function toggleModal() {
        setIsOpen(!isOpen)
    }

    const style = {
        content: {
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '12px',
            outline: 'none',
            padding: '18px',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-40%',
            width: '90%',
            maxWidth: '860px',
            transform: 'translate(-50%, -50%)'
        },
        overlay: {
            backdropFilter: 'blur(1px)',
            backgroundColor: 'rgba(0,0,0,0.2)',
            zIndex: 1000
        }
    }

    return (
        <div className="container">
            <div>
                <Modal
                    isOpen={isOpen}
                    onRequestClose={toggleModal}
                    contentLabel="My dialog"
                    style={style}
                >
                    {isOpen ? (
                        <div className="text-center">
                            <div className="py-8">
                                {/*header*/}
                                <div className="text-4xl md:text-5xl font-bold text-center">
                                    <p>WOULD YOU LIKE </p>
                                    <p className="text-5xl md:text-6xl text-lndred">15% OFF</p>
                                    <p>YOUR FIRST ORDER?</p>
                                    <hr className='mt-4 w-44 mx-auto border-black' />
                                    <p className="text-lg leading-relaxed font-medium pt-4 text-center">
                                        Subscribe to our newsletter & recieve an instant coupon to your E-mail
                                    </p>
                                </div>
                                <div className="fixed top-5 right-5">
                                    <button className="text-lg lg:text-2xl font-bold hover:text-lndred transition duration-100 ease-in-out" title="Close Pop-up" alt="Close Pop-up" onClick={toggleModal}>X</button>
                                </div>
                                {/*footer*/}
                                <div className="pt-2">
                                    <a href="https://l-d-solutions.myshopify.com/pages/email-signup" rel="noreferrer" title="Sign-Up For L&D Solutions Newsletter" alt="Sign-Up For L&D Solutions Newsletter">
                                        <button
                                            className="relative h-10 py-2 px-8 mx-auto transition-colors rounded-lg border border-lndred focus:shadow-outline bg-lndred text-white hover:shadow-lg hover:shadow-lndred font-semibold transition duration-150 ease-in-out"
                                            type="button"
                                            title="Get 15% Off"
                                            alt="Get 15% Off"
                                            onClick={() => toggleModal()}
                                        >
                                            YES, GIVE ME 15% OFF
                                        </button>
                                    </a>
                                </div>
                                <div className="flex items-center justify-center pt-6 rounded-b">
                                    <button
                                        className="hover:text-lndred transition duration-150 ease-in-out"
                                        type="button"
                                        title="Reject Discount"
                                        alt="Reject Discount"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        NO THANKS, I DON'T LIKE TO SAVE MONEY
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </Modal>
            </div>
        </div>
    )
}