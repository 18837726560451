import React, { Component } from "react"

export class BannerSteps extends Component {
    render() {
        return (
            <div className="container mx-auto">
                <div className="pb-10 lg:pt-10 lg:pb-20 px-5 mx-auto">
                    <p className="text-lndred text-sm text-center lg:text-left xl:px-24 2xl:px-[192px]">For You </p>
                    <p className="font-bold text-2xl text-center lg:text-left xl:px-24 2xl:px-[190px] lg:text-3xl">
                        Get The Most From Our Services
                    </p>
                    <div className="grid lg:flex justify-center mx-auto max-w-7xl pt-7 space-y-7 lg:space-y-0 lg:space-x-5 2xl:space-x-24">
                        <div className="max-w-lg bg-newlndgray p-5">
                            <p className="text-black font-semibold text-xl">
                                If You Are A Collision Shop Or Auto Rebuilder...
                            </p>
                            <div className="py-3 px-5">
                                <ul className="space-y-3 list-decimal marker:text-lndred marker:font-bold">
                                    <li dangerouslySetInnerHTML={{ __html: this.props.homePageData[0].node.ifYouAreACollisionShopOrAutoRebuilder1.childMarkdownRemark.html }} />
                                    <li dangerouslySetInnerHTML={{ __html: this.props.homePageData[0].node.ifYouAreACollisionShopOrAutoRebuilder2.childMarkdownRemark.html }} />
                                    <li dangerouslySetInnerHTML={{ __html: this.props.homePageData[0].node.ifYouAreACollisionShopOrAutoRebuilder3.childMarkdownRemark.html }} />
                                </ul>
                            </div>
                        </div>
                        <div className="max-w-lg bg-newlndgray p-5">
                            <p className="text-black font-semibold text-xl">
                                If You Are A Hobby Auto Rebuilder...
                            </p>
                            <div className="py-3 px-5">
                                <ul className="space-y-3 list-decimal marker:text-lndred marker:font-bold">
                                <li dangerouslySetInnerHTML={{ __html: this.props.homePageData[0].node.ifYouAreAHobbyAutoRebuilder1.childMarkdownRemark.html }} />
                                <li dangerouslySetInnerHTML={{ __html: this.props.homePageData[0].node.ifYouAreAHobbyAutoRebuilder2.childMarkdownRemark.html }} />
                                <li dangerouslySetInnerHTML={{ __html: this.props.homePageData[0].node.ifYouAreAHobbyAutoRebuilder3.childMarkdownRemark.html }} />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}